// src/erp/BroadcastMessageForm.js

import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  TablePagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Container, Box, List, ListItem, ListItemText, Paper 
} from '@mui/material';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextMessageForm from './Line/TextMessageForm';
import StickerMessageForm from './Line/StickerMessageForm';
import ImageMessageForm from './Line/ImageMessageForm';
import ImagemapMessageForm from './Line/ImagemapMessageForm';
import FlexMessageForm from './Line/FlexMessageForm';
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

const messageTypes = [
  { value: 'text', label: '文字訊息' },
  { value: 'sticker', label: '貼圖訊息' },
  { value: 'image', label: '圖片訊息' },
  // { value: 'video', label: '影片訊息' },
  // { value: 'audio', label: '音訊訊息' },
  // { value: 'location', label: '位置訊息' },
  { value: 'imagemap', label: 'Imagemap 訊息' },
  // { value: 'template_buttons', label: '按鈕模板訊息' },
  // { value: 'template_confirm', label: '確認模板訊息' },
  { value: 'flexCarousel', label: '彈性訊息(輪播)' },
];

const BroadcastMessageForm = () => {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  // Data grid states (simplified for brevity)
  const tomorrow = dayjs().add(1, 'day'); // 取得明天的日期
  const [time, setTime] = React.useState(tomorrow);
  const [information, setInformation] = useState({
    fansCount: 0,
    priceList: {}
  });
  const [filterRows, setFilterRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [messageType, setMessageType] = useState('text');
  const messageFormRef = useRef();

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'broadcastMessage',
      headerName: '廣告類別',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        const messages = params.value?.messages;
    
        if (messages && messages.length > 0) {
          const messageType = messages[0].type; // 提取第一個消息的類型 
          switch (messageType) {
            case 'sticker':
              return '貼圖訊息';
            case 'text':
              return '文字訊息';
            case 'image':
              return '圖片訊息';
            // 添加其他消息類型的處理
            case 'imagemap':
              return '圖文互動';
            case 'flexCarousel':
              return '彈性訊息';
            default:
              return '彈性訊息';
          }
        } 
        return '無訊息';
      },
    },
    {
      field: 'price',
      headerName: '價格',
      width: 90,
      editable: false,
    },
    {
      field: 'peopleAmount',
      headerName: '接觸人數',
      width: 120,
      editable: false,
    },
    {
      field: 'hasPass',
      headerName: '發送狀態',
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        switch (params.value) {
          case true:
            return '已發送';
          case false:
            return '尚未發送';
          default:
            return '未知';
        }
      },
    },
    {
      field: 'isValidated',
      headerName: '審核狀態',
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return '審核通過';
          case 0:
            return '審核中';
          case -1:
            return '審核失敗';
          default:
            return '未知';
        }
      },
    },
    {
      field: 'statusMessage',
      headerName: '備註',
      width: 120,
      editable: false,
    },
    {
      field: 'createDate',
      headerName: '申請時間',
      width: 150,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2); 
        const hours = (`0${date.getUTCHours()}`).slice(-2);     
        return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()} ${hours}:${minutes}`;
      }, 
    },
    {
      field: 'broadcastDate',
      headerName: '預約發送',
      width: 150,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2); 
        const hours = (`0${date.getUTCHours()}`).slice(-2);     
        return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()} ${hours}:${minutes}`;
      }, 
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleTestSubmit = async () => {
    let messageData = {};

    if (messageFormRef.current && messageFormRef.current.getMessageData) {
      messageData = messageFormRef.current.getMessageData();
    } else {
      alert('無法取得訊息資料');
      return;
    }

    const broadcastRequest = {
      Messages: [messageData],
      // NotificationDisabled: false // 根據需要設定，可選
    };
    try {
      const response = await axios.post(
        `${API_PATH}/manufacturer/broadcast/test?messageType=${messageType}`,
        broadcastRequest
      );
      if (response.status === 200) { 
        alert('推播訊息成功');
        fetchData();
      }   
      // 可以在此處清空表單或進行其他操作
    } catch (error) {
      console.error('推播訊息失敗:', error);
      alert('推播訊息失敗');
    }
  };
  // Fetch data function (simplified for brevity)
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/broadcast?pageNumber=${page+1}
        &pageSize=${rowsPerPage}`);
  
      if (response.status === 200) { 
        setFilterRows(response.data.source);
        setTotalRows(response.data.totalItemCount)
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const fetchOAData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/fans`);
      if (response.status === 200) {  
        setInformation(response.data.source);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchOAData();
  }, [page, rowsPerPage]);


  // Handle form submission
  const handleSubmit = async () => {
    let messageData = {};

    if (messageFormRef.current && messageFormRef.current.getMessageData) {
      messageData = messageFormRef.current.getMessageData();
    } else {
      alert('無法取得訊息資料');
      return;
    }

    const broadcastRequest = {
      Messages: [messageData],
      // NotificationDisabled: false // 根據需要設定，可選
    };

    try {
      const response = await axios.post(
        `${API_PATH}/manufacturer/broadcast?messageType=${messageType}&broadcastDate=${time.format('YYYY-MM-DD HH:mm')}&people=${information.fansCount}&`,
        broadcastRequest
      );
      if (response.status === 200) { 
        alert('推播訊息成功');
        fetchData();
      }   
      // 可以在此處清空表單或進行其他操作
    } catch (error) {
      console.error('推播訊息失敗:', error);
      alert('推播訊息失敗');
    }
  };


  // Render message form based on selected messageType
  const renderMessageForm = () => {
    switch (messageType) {
      case 'text':
        return <TextMessageForm ref={messageFormRef} />;
      case 'sticker':
        return <StickerMessageForm ref={messageFormRef} />;
      case 'image':
        return <ImageMessageForm ref={messageFormRef} />;
      case 'imagemap':
        return <ImagemapMessageForm ref={messageFormRef} />;
      case 'flexCarousel':
        return <FlexMessageForm ref={messageFormRef}/>;
      // Add cases for other message types if needed
      default:
        return null;
    }
  };

  return (
  <Grid container spacing={3} style={{ padding: 20 }}>
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* 外層 Box 分為左右兩部分 */}
      <Box display="flex" justifyContent="space-between" alignItems="stretch" sx={{ gap: 3 }}>
        {/* 左側 Box 包含 A 和 B 區塊，垂直排列 */}
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ gap: 3, height: '100%' }}
        >
          {/* A 區塊：粉絲人數，佔左側 40% 高度 */}
          <Paper
            elevation={3}
            sx={{
              p: 3,
              flex: '0 0 40%', // 固定 40% 高度
              backgroundColor: '#f5f5f5',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4" gutterBottom align="center" color="primary">
              粉絲人數
            </Typography>
            <Typography variant="h2" align="center" color="secondary">
              {information.fansCount}
            </Typography>
          </Paper>

          {/* B 區塊：操作說明，佔左側 60% 高度 */}
          <Paper
            elevation={3}
            sx={{
              p: 3,
              flex: '0 0 60%', // 固定 60% 高度
              backgroundColor: '#f5f5f5',
            }}
          >
            <Typography variant="h4" gutterBottom align="center" color="primary">
              推播說明
            </Typography>
              <Box>
                <Typography variant="body2" align="start" gutterBottom>
                  1. 推播成功後才會扣款
                </Typography>
                
                <Typography variant="body2" align="start" gutterBottom>
                  2. 推播內容會審核，請勿違反合約推播內容
                </Typography>

                <Typography variant="body2" align="start" gutterBottom>
                  2-1. 可推廣自己實體店面、商品、不得推廣自己的蝦皮與個人賣場或其他同性質抽獎平台
                </Typography>
                <Typography variant="body2" align="start" gutterBottom>
                  2-2. 0時~4時、4時~8時、8時~12時、12時~16時、16時~20時、20時~24時
                  一天共六個時段，每個時段不得推播超過4則，與其他廠商先搶先贏
                </Typography>
                <Typography variant="body2" align="start" gutterBottom>
                  3. 模擬畫面僅供參考
                </Typography>
                
                <Typography variant="body2" align="start" gutterBottom>
                  4. 測試推播一天可測試三十次，用於檢測畫面
                </Typography>
                
                <Typography variant="body2" align="start" gutterBottom>
                  5. 若要使用測試推播，請先加入官方LINE粉絲，並且在聊天室輸入以下文字:
                </Typography>
                
                <Typography variant="body2" align="start" gutterBottom>
                  綁定廠商 : XXXXXX
                </Typography>
                
                <Typography variant="body2" align="start" gutterBottom>
                  其中 XXXXX為您的後台帳號，綁定成功後即可推播測試帳號
                </Typography>
                <Typography variant="body2" align="start" gutterBottom>
                  6. 目前推播皆免費哦!!! 請善用此功能打廣告 !!!!! 後續有收費會再通知~~~
                </Typography>
              </Box>
          </Paper>
        </Box>

        {/* 右側區塊：價目表 */}
        <Paper
          elevation={3}
          sx={{
            p: 3,
            flex: 1, // 佔用剩餘的寬度
            backgroundColor: '#fafafa',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" gutterBottom color="primary" align="center">
            價目表
          </Typography>
          <List>
            {Object.entries(information.priceList).map(([key, value]) => (
              <ListItem key={key} sx={{ justifyContent: 'center' }}>
                <ListItemText primary={`${key}訊息: 一則 ${value}元 乘以粉絲數 再乘廠商折扣`} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Container>
      <Grid item xs={12} style={{ height: '500px' }}>
        <DataGridPro
          rows={filterRows}
          columns={columns}
          disableRowSelectionOnClick
          style={{ height: '100%', width: '100%' }}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[50, 100, 200, 500]}
        />
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="想發送的時間 會在該時段發送"
              value={time}
              onChange={(newValue) => setTime(newValue)}
              inputFormat="YYYY-MM-DD HH:mm" // 控制輸入框顯示的格式
              renderInput={(params) => <TextField {...params} />}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          label="訊息類型"
          value={messageType}
          onChange={(e) => setMessageType(e.target.value)}
          fullWidth
          variant="outlined"
        >
          {messageTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Dynamic Message Form */}
      <Grid item xs={12}>
        {renderMessageForm()}
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ maxWidth: '200px' }}
          onClick={handleSubmit}
        >
          送出推播訊息
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ maxWidth: '200px' }}
          onClick={handleTestSubmit}
        >
          送出測試訊息
        </Button>
      </Grid>
    </Grid>
  );
};

export default BroadcastMessageForm;
