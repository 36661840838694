// @mui
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// utils
import { fToNow } from '../../../utils/formatTime';
// @mui
import {
  Card,
  Stack,
  Divider,
  Button,
  Typography,
  Box,
  Collapse,
  CardHeader,
  CardContent
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTasks({ title, subheader, list, ...other }) {
  // 引入狀態，用於控制是否顯示所有任務
  const [showAll, setShowAll] = useState(false);

  // 根據狀態決定要顯示的任務數量
  const displayedList = showAll ? list : list.slice(0, 5);
  const remainingList = showAll ? [] : list.slice(5);

  // 按鈕點擊事件處理器
  const handleToggleShow = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        {/* 顯示前5筆任務 */}
        <Stack spacing={1}>
          {displayedList.map((task) => (
            <TaskItem
              key={task.id}
              task={task}
            />
          ))}
        </Stack>

        {/* 使用 Collapse 來包裹剩餘的任務，並添加動畫效果 */}
        {remainingList.length > 0 && (
          <Collapse in={showAll} timeout="auto" unmountOnExit>
            <Stack spacing={1} sx={{ mt: 1 }}>
              {remainingList.map((task) => (
                <TaskItem
                  key={task.id}
                  task={task}
                />
              ))}
            </Stack>
          </Collapse>
        )}
      </CardContent>

      {/* Divider only if there are tasks */}
      {list.length > 0 && <Divider />}

      <Box sx={{ p: 2, textAlign: 'right' }}>
        {list.length > 5 && (
          <Button
            size="small"
            color="inherit"
            endIcon={<Iconify icon={showAll ? 'eva:arrow-ios-up-fill' : 'eva:arrow-ios-down-fill'} />}
            onClick={handleToggleShow}
          >
            {showAll ? '收起' : '查看全部'}
          </Button>
        )}
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    postedAt: PropTypes.string,
  }),
};

function TaskItem({ task }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        py: 0.75,
      }}
    >
      {task.image && (
        <Box
          component="img"
          src={task.image}
          alt={task.label}
          sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0, mr: 2 }}
          onError={(e) => { e.target.onerror = null; e.target.src = '/path-to-default-image.png'; }}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: 14, whiteSpace: 'pre-line' }}>
          {task.label}
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(task.postedAt)}
      </Typography>
    </Stack>
  );
}
