// FlexMessageForm.js
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import {
  Button,
  Typography,
  TextField,
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import FlexMessage1_1Preview from './LineObjectList/FlexMessage1_1Preview';
import FlexMessage1_2Preview from './LineObjectList/FlexMessage1_2Preview';
import FlexMessage1_3Preview from './LineObjectList/FlexMessage1_3Preview';
import { templates } from './LineObjectList/Templates'; // 確保路徑正確
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { set, get, cloneDeep } from 'lodash'; // 引入 lodash 的 set、get 和 cloneDeep 方法

const API_PATH = process.env.REACT_APP_API_PATH;

// 定義按鈕文字顏色選項
const BUTTON_TEXT_COLOR_OPTIONS = {
  primary: "#FFFFFF",
  secondary: "#000000",
};

// 自定義樣式
const PreviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#f9f9f9',
  boxShadow: theme.shadows[3],
}));

// 根據模板索引取得預設泡泡
const getDefaultBubble = (templateIndex) => {
  const template = templates[templateIndex];
  if (template && template.data && template.data.contents && template.data.contents.length > 0) {
    return cloneDeep(template.data.contents[0]); // 複製該模板的第一個泡泡作為預設
  } else {
    // 如果模板沒有定義泡泡，返回一個空的泡泡結構
    return {
      type: "bubble",
      hero: {
        type: "image",
        size: "full",
        aspectRatio: "20:13",
        aspectMode: "cover",
        url: "",
      },
      body: {
        type: "box",
        layout: "vertical",
        spacing: "sm",
        contents: [],
      },
      footer: {
        type: "box",
        layout: "vertical",
        spacing: "sm",
        contents: [],
      },
    };
  }
};

const FlexMessageForm = forwardRef(({ initialData }, ref) => {
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [flexMessageData, setFlexMessageData] = useState({
    type: "carousel",
    contents: templates[0]?.data?.contents ? cloneDeep(templates[0].data.contents) : [],
  });

  // 新增 altText 狀態
  const [altText, setAltText] = useState('這是一個 Flex Message');

  // 將 getMessageData 方法暴露給父組件
  useImperativeHandle(ref, () => ({
    getMessageData: () => {
      return {
        type: 'flex',
        altText: altText,
        contents: flexMessageData, // 直接傳遞 flexMessageData 物件
      };
    },
  }));

  // 處理圖片上傳
  const handleImageUpload = async (file, setUrlCallback) => {
    if (!file) return;
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      alert('圖片格式必須為 JPEG 或 PNG');
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert('圖片大小不能超過 10MB');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${API_PATH}/manufacturer/upload/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = response.data.url; // 後端返回的圖片網址
      setUrlCallback(imageUrl);
    } catch (error) {
      console.error('圖片上傳失敗:', error);
      alert('圖片上傳失敗');
    }
  };

  // 更新 Flex Message 數據
  const handleChange = (path, value) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      set(newData, path, value);
      console.log('更新後的 Flex Message 數據:', newData); // 添加日誌
      return newData;
    });
  };

  // 新增泡泡，根據選擇的模板添加相應的預設結構
  const addBubble = () => {
    if (flexMessageData.contents.length >= 10) {
      alert('最多只能有10個泡泡');
      return;
    }
    const newBubble = getDefaultBubble(selectedTemplateIndex);
    console.log(`新增模板 ${selectedTemplateIndex + 1} 的泡泡`);
    setFlexMessageData((prev) => ({
      ...prev,
      contents: [...prev.contents, newBubble],
    }));
  };

  // 移除泡泡
  const removeBubble = (index) => {
    if (flexMessageData.contents.length <= 1) {
      alert('至少需要一個泡泡');
      return;
    }
    setFlexMessageData((prev) => ({
      ...prev,
      contents: prev.contents.filter((_, i) => i !== index),
    }));
  };

  // 新增小字
  const addSmallText = (pathPrefix) => {
    const newText = {
      type: "text",
      text: "新小字",
      wrap: true,
      size: "xxs",
      margin: "md",
      color: "#ff5551",
      flex: 0,
    };
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      set(newData, pathPrefix, [...get(newData, pathPrefix, []), newText]);
      return newData;
    });
  };

  // 移除小字
  const removeSmallText = (pathPrefix, index) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      if (currentArray.length > index) {
        currentArray.splice(index, 1);
        set(newData, pathPrefix, currentArray);
      }
      return newData;
    });
  };

  // 新增頁尾按鈕
  const addFooterButton = (pathPrefix) => {
    const newButton = {
      type: "button",
      style: "primary",
      color: "#1DB446", // 默認按鈕背景顏色
      textColor: "#FFFFFF", // 默認按鈕文字顏色
      action: {
        type: "uri",
        label: "新按鈕",
        uri: "https://line.me/",
      },
    };
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      set(newData, pathPrefix, [...get(newData, pathPrefix, []), newButton]);
      return newData;
    });
  };

  // 移除頁尾按鈕
  const removeFooterButton = (pathPrefix, index) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      if (currentArray.length > index) {
        currentArray.splice(index, 1);
        set(newData, pathPrefix, currentArray);
      }
      return newData;
    });
  };

  // 新增 Icon（不含尺寸和文字選項）
  const addIcon = (pathPrefix) => {
    const newIcon = {
      type: "icon",
      url: "",
      label: "", // 新增標籤屬性
    };
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      set(newData, pathPrefix, [...currentArray, newIcon]);
      return newData;
    });
  };

  // 移除 Icon
  const removeIcon = (pathPrefix, index) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      if (currentArray.length > index) {
        currentArray.splice(index, 1);
        set(newData, pathPrefix, currentArray);
      }
      return newData;
    });
  };

  // 上移 Icon
  const moveIconUp = (pathPrefix, index) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      if (index === 0) return prev; // 不能上移
      [currentArray[index - 1], currentArray[index]] = [currentArray[index], currentArray[index - 1]];
      set(newData, pathPrefix, currentArray);
      return newData;
    });
  };

  // 下移 Icon
  const moveIconDown = (pathPrefix, index) => {
    setFlexMessageData((prev) => {
      const newData = cloneDeep(prev);
      const currentArray = get(newData, pathPrefix, []);
      if (index === currentArray.length - 1) return prev; // 不能下移
      [currentArray[index + 1], currentArray[index]] = [currentArray[index], currentArray[index + 1]];
      set(newData, pathPrefix, currentArray);
      return newData;
    });
  };

  // 增強 renderBodyContents 函數以支持所有內容類型，包括 Icon 和 Text
  const renderBodyContents = (contents, pathPrefix) => {
    if (!Array.isArray(contents)) return null;

    return (
      <div>
        {contents.map((content, index) => {
          if (!content) return null;

          // 處理文字內容
          if (content.type === 'text') {
            return (
              <Box key={`${pathPrefix}.${index}`} mt={2} border="1px solid #ddd" p={2} borderRadius="4px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">文字 {index + 1}</Typography>
                  <IconButton
                    aria-label="移除文字"
                    color="secondary"
                    onClick={() => removeSmallText(pathPrefix, index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
                <TextField
                  label="文字內容"
                  value={content.text || ''}
                  onChange={(e) => handleChange(`${pathPrefix}.${index}.text`, e.target.value)}
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '8px' }}
                />
                <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
                  <InputLabel>文字大小</InputLabel>
                  <Select
                    value={content.size || 'sm'}
                    label="文字大小"
                    onChange={(e) => handleChange(`${pathPrefix}.${index}.size`, e.target.value)}
                  >
                    <MenuItem value="xxs">超超小</MenuItem>
                    <MenuItem value="xs">超小</MenuItem>
                    <MenuItem value="sm">小</MenuItem>
                    <MenuItem value="md">中</MenuItem>
                    <MenuItem value="lg">大</MenuItem>
                    <MenuItem value="xl">超大</MenuItem>
                  </Select>
                </FormControl>
                <Box display="flex" alignItems="center" mt={2}>
                  <TextField
                    label="文字顏色"
                    type="color"
                    value={content.color || '#000000'}
                    onChange={(e) => handleChange(`${pathPrefix}.${index}.color`, e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px', width: '60px' }} // 增加寬度
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Typography>選擇顏色</Typography>
                </Box>
              </Box>
            );
          }

          // 處理圖片內容
          if (content.type === 'image') {
            return (
              <Box key={`${pathPrefix}.${index}`} mt={2} border="1px solid #ddd" p={2} borderRadius="4px">
                <Typography variant="subtitle2">圖片 {index + 1}</Typography>
                <TextField
                  label="圖片 URL"
                  value={content.url || ''}
                  onChange={(e) => handleChange(`${pathPrefix}.${index}.url`, e.target.value)}
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '8px' }}
                />
                <Button
                  variant="contained"
                  component="label"
                  style={{ marginTop: '8px' }}
                >
                  上傳圖片
                  <input
                    type="file"
                    hidden
                    accept="image/jpeg,image/png"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      handleImageUpload(file, (url) => handleChange(`${pathPrefix}.${index}.url`, url));
                    }}
                  />
                </Button>
                <IconButton
                  aria-label="移除圖片"
                  color="secondary"
                  onClick={() => {
                    handleChange(`${pathPrefix}.${index}`, null);
                  }}
                  style={{ marginTop: '8px' }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          }

          // 處理 Icon 內容（不含尺寸和文字選項）
          if (content.type === 'icon') {
            return (
              <Box key={`${pathPrefix}.${index}`} mt={2} border="1px solid #ddd" p={2} borderRadius="4px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">Icon {index + 1}</Typography>
                  <IconButton
                    aria-label="移除 Icon"
                    color="secondary"
                    onClick={() => removeIcon(pathPrefix, index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
                <TextField
                  label="Icon URL"
                  value={content.url || ''}
                  onChange={(e) => handleChange(`${pathPrefix}.${index}.url`, e.target.value)}
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '8px' }}
                />
                {/* Icon 標籤 */}
                <TextField
                  label="Icon 標籤"
                  value={content.label || ''}
                  onChange={(e) => handleChange(`${pathPrefix}.${index}.label`, e.target.value)}
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '16px' }}
                />
                {/* 移動 Icon 按鈕 */}
                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <IconButton
                    aria-label="上移 Icon"
                    color="primary"
                    onClick={() => moveIconUp(pathPrefix, index)}
                    disabled={index === 0}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="下移 Icon"
                    color="primary"
                    onClick={() => moveIconDown(pathPrefix, index)}
                    disabled={index === get(flexMessageData, pathPrefix, []).length - 1}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          }

          // 處理 filler 內容
          if (content.type === 'filler') {
            return (
              <Box key={`${pathPrefix}.${index}`} mt={2} border="1px solid #ddd" p={2} borderRadius="4px">
                <Typography variant="subtitle2">Filler {index + 1}</Typography>
                <Typography variant="body2">這是一個 filler，不可編輯。</Typography>
              </Box>
            );
          }

          // 處理包含其他 box 的內容（遞歸）
          if (content.type === 'box' && content.contents) {
            return (
              <Box key={`${pathPrefix}.${index}`} mt={2} pl={2} borderLeft="2px solid #ccc">
                <Typography variant="subtitle2">內部內容 {index + 1}</Typography>
                {renderBodyContents(content.contents, `${pathPrefix}.${index}.contents`)}
              </Box>
            );
          }

          // 其他類型不處理
          return null;
        })}

        {/* 顯示添加 Icon 和添加文字按鈕 */}
        <Box mt={2} display="flex" gap={2}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => addIcon(pathPrefix)}
          >
            新增 Icon
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => addSmallText(pathPrefix)}
          >
            新增文字
          </Button>
        </Box>
      </div>
    );
  };

  // 渲染 Footer 按鈕
  const renderFooterButtons = (buttons, pathPrefix) => {
    if (!Array.isArray(buttons)) return null;
    return buttons.map((button, index) => {
      if (!button.action) return null;
      return (
        <Box key={`${pathPrefix}.${index}`} mt={2} p={2} border="1px dashed #aaa" borderRadius="4px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">按鈕 {index + 1}</Typography>
            <IconButton
              aria-label="移除"
              color="secondary"
              onClick={() => removeFooterButton(pathPrefix, index)}
            >
              <RemoveIcon />
            </IconButton>
          </Box>
          <TextField
            label="按鈕標籤"
            value={button.action.label || ''}
            onChange={(e) => handleChange(`${pathPrefix}.${index}.action.label`, e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginTop: '8px' }}
          />
          <TextField
            label="按鈕 URI"
            value={button.action.uri || ''}
            onChange={(e) => handleChange(`${pathPrefix}.${index}.action.uri`, e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginTop: '8px' }}
          />
          {/* 按鈕背景顏色選擇器 */}
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="按鈕背景顏色"
              type="color"
              value={button.color || '#1DB446'}
              onChange={(e) => handleChange(`${pathPrefix}.${index}.color`, e.target.value)}
              variant="outlined"
              style={{ marginRight: '16px', width: '60px' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography>背景顏色</Typography>
          </Box>
          {/* 按鈕文字顏色選擇器 */}
          <Box display="flex" alignItems="center" mt={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>按鈕文字顏色</InputLabel>
              <Select
                value={
                  Object.keys(BUTTON_TEXT_COLOR_OPTIONS).find(key => BUTTON_TEXT_COLOR_OPTIONS[key] === button.textColor) || 'primary'
                }
                label="按鈕文字顏色"
                onChange={(e) => handleChange(`${pathPrefix}.${index}.textColor`, BUTTON_TEXT_COLOR_OPTIONS[e.target.value])}
              >
                {Object.keys(BUTTON_TEXT_COLOR_OPTIONS).map((key) => (
                  <MenuItem value={key} key={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      );
    });
  };

  // 渲染泡泡表單
  const renderBubbleForm = (bubble, bubbleIndex) => {
    const smallTextsPath = `contents.${bubbleIndex}.body.contents`;
    const footerButtonsPath = `contents.${bubbleIndex}.footer.contents`;

    // 獲取所有小文字的索引
    const smallTexts = bubble.body.contents.filter(content => content.type === 'text' && content.size === 'xxs');

    return (
      <Box key={bubbleIndex} mt={4} p={2} border="1px solid #ccc" borderRadius="8px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">泡泡 {bubbleIndex + 1}</Typography>
          <IconButton
            aria-label="移除"
            color="secondary"
            onClick={() => removeBubble(bubbleIndex)}
            disabled={flexMessageData.contents.length <= 1}
          >
            <DeleteIcon />
          </IconButton>
        </Box>

        {/* 主圖像（如果存在的話） */}
        {bubble.hero && (
          <Box mt={2} border="1px solid #ddd" p={2} borderRadius="4px">
            <Typography variant="subtitle1">主圖像</Typography>
            <TextField
              label="圖片 URL"
              value={bubble.hero.url || ''}
              onChange={(e) => handleChange(`contents.${bubbleIndex}.hero.url`, e.target.value)}
              fullWidth
              variant="outlined"
              style={{ marginTop: '8px' }}
            />
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: '8px' }}
            >
              上傳圖片
              <input
                type="file"
                hidden
                accept="image/jpeg,image/png"
                onChange={(e) => {
                  const file = e.target.files[0];
                  handleImageUpload(file, (url) => handleChange(`contents.${bubbleIndex}.hero.url`, url));
                }}
              />
            </Button>
            {/* 移除圖片按鈕 */}
            {bubble.hero.url && (
              <IconButton
                aria-label="移除圖片"
                color="secondary"
                onClick={() => handleChange(`contents.${bubbleIndex}.hero.url`, "")}
                style={{ marginTop: '8px' }}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        )}

        {/* 正文文字 */}
        <Box mt={2}>
          <Typography variant="subtitle1">正文文字</Typography>
          {bubble.body && bubble.body.contents ? (
            renderBodyContents(bubble.body.contents, `contents.${bubbleIndex}.body.contents`)
          ) : (
            <Typography color="error">此泡泡缺少 body.contents 屬性。</Typography>
          )}
        </Box>

        {/* 頁尾按鈕 */}
        <Box mt={2}>
          <Typography variant="subtitle1">頁尾按鈕</Typography>
          {bubble.footer && bubble.footer.contents ? (
            <>
              {renderFooterButtons(bubble.footer.contents, footerButtonsPath)}
              {/* 添加頁尾按鈕 */}
              <Box mt={2}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => addFooterButton(footerButtonsPath)}
                >
                  新增頁尾按鈕
                </Button>
              </Box>
            </>
          ) : (
            <Typography color="error">此泡泡缺少 footer.contents 屬性。</Typography>
          )}
        </Box>
      </Box>
    );
  };

  // 根據選擇的模板更新 flexMessageData
  useEffect(() => {
    const selectedTemplate = templates[selectedTemplateIndex];
    if (selectedTemplate && selectedTemplate.data && selectedTemplate.data.contents) {
      setFlexMessageData({
        type: "carousel",
        contents: cloneDeep(selectedTemplate.data.contents), // 深拷貝避免引用問題
      });
      console.log('選擇的模板已載入，不修改尺寸。');
    } else {
      setFlexMessageData({
        type: "carousel",
        contents: [],
      });
      console.log('選擇的模板沒有內容，重置為空的輪播。');
    }
  }, [selectedTemplateIndex]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', padding: '20px', boxSizing: 'border-box' }}>
      {/* 表單部分 */}
      <Box sx={{ flex: '0 0 33.3333%', overflowY: 'auto', paddingRight: '20px' }}>
        {/* 模板選擇 */}
        <Typography variant="h6">選擇 Flex Message 模板</Typography>
        <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
          <InputLabel>模板</InputLabel>
          <Select
            value={selectedTemplateIndex}
            label="模板"
            onChange={(e) => setSelectedTemplateIndex(e.target.value)}
          >
            {templates.map((template, index) => (
              <MenuItem value={index} key={index}>
                {template.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* AltText 編輯 */}
        <Box sx={{ mt: 2, border: '1px solid #ccc', p: 2, borderRadius: '8px' }}>
          <TextField
            label="LINE 顯示的替代文字"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ marginTop: '8px' }}
          />
        </Box>

        {/* Carousel 編輯區域 */}
        <Typography variant="h6" sx={{ marginTop: '24px' }}>輪播編輯</Typography>
        {flexMessageData.contents.map((bubble, index) => renderBubbleForm(bubble, index))}

        {/* 添加泡泡按鈕 */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={addBubble}
            disabled={flexMessageData.contents.length >= 10}
            startIcon={<AddIcon />}
          >
            新增泡泡
          </Button>
          <Typography variant="caption" display="block" color="textSecondary">
            最多10個泡泡
          </Typography>
        </Box>
      </Box>

      {/* 預覽部分 */}
      <Box sx={{ flex: '1 1 auto', overflowY: 'auto', position: 'relative' }}>
        <Box 
          position="sticky" 
          top={20}
        >
          <PreviewContainer>
            {selectedTemplateIndex === 2 && <FlexMessage1_3Preview flexMessage={flexMessageData} />}
            {selectedTemplateIndex === 1 && <FlexMessage1_2Preview flexMessage={flexMessageData} />}
            {selectedTemplateIndex === 0 && <FlexMessage1_1Preview flexMessage={flexMessageData} />}
            {/* 如果有更多模板，繼續添加預覽組件 */}
          </PreviewContainer>
        </Box>
      </Box>
    </Box>
  );
});

export default FlexMessageForm;
