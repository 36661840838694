// @mui
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Typography, CardHeader, CardContent, Button, Box, Divider } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

AppLastNewsline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppLastNewsline({ title, subheader, list, ...other }) {
  const [showAll, setShowAll] = useState(false);
  const displayedList = showAll ? list : list.slice(0, 5);

  const handleToggleShow = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Scrollbar>
          <Timeline>
            {displayedList.length > 0 ? (
              displayedList.map((item, index) => (
                <OrderItem key={item.id} item={item} isLast={index === displayedList.length - 1} />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                沒有最新的更新
              </Typography>
            )}
          </Timeline>
        </Scrollbar>
      </CardContent>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        {list.length > 5 && (
          <Button
            size="small"
            color="inherit"
            endIcon={<Iconify icon={showAll ? 'eva:arrow-ios-up-fill' : 'eva:arrow-ios-down-fill'} />}
            onClick={handleToggleShow}
          >
            {showAll ? 'Show less' : 'View all'}
          </Button>
        )}
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;

  // 根據 title 和 type 判斷等級
  const level =
    (title.includes('C') && 'primary 普通\n備註:稍微注意一下即可') ||
    (title.includes('D') && 'primary 普通\n備註:稍微注意一下即可') ||
    (title.includes('E') && 'primary 普通\n備註:稍微注意一下即可') ||
    (title.includes('B') && 'success 有點痛\n備註:注意一下即可') ||
    (title.includes('A') && 'info 會痛\n備註:注意荷包有無大失血') ||
    (title.includes('SP') && 'warning 這次真的痛\n備註:注意荷包有無大失血') ||
    (title.includes('最後') && 'error 恭喜\n備註:該系列已成功賣光') ||
    (type === '安全量提醒' && 'error 恭喜\n備註:該系列快要賣光了哦');

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (title.includes('C') && 'primary') ||
            (title.includes('D') && 'primary') ||
            (title.includes('E') && 'primary') ||
            (title.includes('B') && 'success') ||
            (title.includes('A') && 'info') ||
            (title.includes('SP') && 'warning') ||
            (title.includes('最後') && 'error') ||
            (type === '安全量提醒' && 'error')
          }
        />
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
          {title} 等級: {level}
        </Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
