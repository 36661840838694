// src/sections/auth/LoginForm.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

const API_PATH = process.env.REACT_APP_API_PATH;

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [acc, setAcc] = useState('');
  const [pw, setPw] = useState('');
  const [loading, setLoading] = useState(false);

  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_PATH}/manufacturer/login`, { Account: acc, Password: pw });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.source);
        const decodedToken = parseJwt(response.data.source);
        if (decodedToken) {
          localStorage.setItem('name', decodedToken.Name);
          localStorage.setItem('id', decodedToken.Id);
          navigate('/manufacturer/info', { replace: true });
        } else {
          alert('Token解析失敗');
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data);
      } else {
        alert('登入失敗，請稍後再試。');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="account"
          label="帳號"
          variant="filled"
          value={acc}
          onChange={(e) => setAcc(e.target.value)}
          InputProps={{
            style: { backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 4 },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            input: { color: '#fff' },
            '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
              borderBottom: 'none',
            },
          }}
        />

        <TextField
          name="password"
          label="密碼"
          type={showPassword ? 'text' : 'password'}
          variant="filled"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" sx={{ color: '#fff' }}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
            style: { backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 4 },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            input: { color: '#fff' },
            '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
              borderBottom: 'none',
            },
          }}
        />
      </Stack>

      {/* 如果需要，可以啟用下

方的選項 */}
      {/*
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" sx={{ color: '#fff' }} />
        <Link variant="subtitle2" underline="hover" sx={{ color: '#fff' }}>
          忘記密碼？
        </Link>
      </Stack>
      */}

      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        onClick={handleLogin}
        loading={loading}
        sx={{
          mt: 3,
          bgcolor: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          color: 'white',
          ':hover': {
            bgcolor: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
          },
        }}
      >
        登入
      </LoadingButton>
    </>
  );
}
