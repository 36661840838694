// src/components/ShootingStars.js

import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';

// Styled Canvas to cover the entire screen
const Canvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none', // 允許點擊穿透
});

const ShootingStars = () => {
  const canvasRef = useRef(null);
  const stars = useRef([]);
  const animationRef = useRef(null);

  // 流星構造函數
  const createShootingStar = (options = {}) => {
    const { startX, startY, endX, endY, isClickStar = false } = options;

    let sx, sy, ex, ey, curveStrength;

    if (isClickStar) {
      // 點擊生成的流星：從隨機起點飛向點擊位置，無曲線
      sx = startX !== undefined ? startX : Math.random() * window.innerWidth;
      sy = startY !== undefined ? startY : Math.random() * window.innerHeight * 0.5;
      ex = endX !== undefined ? endX : sx + (Math.random() * 300 - 150);
      ey = endY !== undefined ? endY : sy + (Math.random() * 300 - 150);
      curveStrength = 0; // 無曲線
    } else {
      // 默認流星：從左側外部飛出，帶有微小曲線
      sx = startX !== undefined ? startX : -200; // 從畫面左側外部生成
      sy = startY !== undefined ? startY : Math.random() * window.innerHeight * 0.5;
      ex = endX !== undefined ? endX : window.innerWidth + 200; // 飛出畫面右側
      ey = endY !== undefined ? endY : sy + (Math.random() * 200 - 100);
      curveStrength = (Math.random() - 0.5) * 0.001; // 微小曲線
    }

    const deltaX = ex - sx;
    const deltaY = ey - sy;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const angle = Math.atan2(deltaY, deltaX);
    const speed = isClickStar ? 1 + Math.random() * 2 : 0.5 + Math.random() * 1.5; // 點擊生成的流星可能稍快
    const length = isClickStar ? 300 + Math.random() * 200 : 200 + Math.random() * 200; // 點擊生成的流星尾巴更長
    const opacity = 0.8; // 固定較高不透明度
    const size = isClickStar ? 5 + Math.random() * 3 : 4 + Math.random() * 3; // 點擊生成的流星頭部更大

    return {
      x: sx,
      y: sy,
      ex,
      ey,
      deltaX,
      deltaY,
      distance,
      traveled: 0,
      speed,
      angle,
      originalAngle: angle,
      length,
      opacity,
      size,
      isClickStar,
      curveStrength, // 用於控制曲線
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // 調整畫布大小
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // 初始化一些默認流星
    for (let i = 0; i < 5; i++) { // 增加初始流星數量
      stars.current.push(createShootingStar());
    }

    // 最大流星數量
    const MAX_STARS = 100;

    // 動畫循環
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'lighter';

      stars.current.forEach((star, index) => {
        // 調整流星角度以創造曲線（僅默認流星）
        if (!star.isClickStar) {
          star.angle += star.curveStrength;
        }

        // 計算流星的新位置
        star.x += Math.cos(star.angle) * star.speed;
        star.y += Math.sin(star.angle) * star.speed;
        star.traveled += star.speed;

        // 繪製流星尾巴
        const gradient = ctx.createLinearGradient(
          star.x,
          star.y,
          star.x - Math.cos(star.angle) * star.length,
          star.y - Math.sin(star.angle) * star.length
        );
        gradient.addColorStop(0, `rgba(255, 255, 255, ${star.opacity})`);
        gradient.addColorStop(1, `rgba(255, 255, 255, 0)`);

        ctx.strokeStyle = gradient;
        ctx.lineWidth = 3; // 增加流星尾巴的粗細
        ctx.beginPath();
        ctx.moveTo(star.x, star.y);
        ctx.lineTo(
          star.x - Math.cos(star.angle) * star.length,
          star.y - Math.sin(star.angle) * star.length
        );
        ctx.stroke();

        // 繪製流星頭部
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`; // 亮度調高
        ctx.fill();

        // 判斷流星是否已經飛出畫面或完成移動
        if (
          star.x > canvas.width + star.length ||
          star.y > canvas.height + star.length ||
          star.x < -star.length ||
          star.y < -star.length ||
          star.traveled >= star.distance
        ) {
          stars.current.splice(index, 1);
        }
      });

      // 隨機生成新的默認流星，並限制最大流星數量
      if (stars.current.length < MAX_STARS && Math.random() < 0.01) { // 增加生成概率
        stars.current.push(createShootingStar());
      }

      // 繼續動畫
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    // 點擊事件處理
    const handleClick = (e) => {
      const rect = canvas.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const clickY = e.clientY - rect.top;

      // 生成一顆直線飛向點擊位置的流星
      stars.current.push(createShootingStar({
        startX: -200, // 從左側外部生成
        startY: Math.random() * window.innerHeight * 0.5,
        endX: clickX,
        endY: clickY,
        isClickStar: true, // 標記為點擊生成的流星
      }));
    };

    // 將點擊事件綁定到畫布的父元素
    canvas.parentElement.addEventListener('click', handleClick);

    // 清理
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationRef.current);
      canvas.parentElement.removeEventListener('click', handleClick);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default ShootingStars;
