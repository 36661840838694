// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import CardMedia from '@mui/material/CardMedia';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title,total, color = 'primary', sx, ...other }) {

  function deterGif(title) {
    if (title === '當月銷售額') {
      return 'profit';
    } else if (title === '當月新顧客') {
      return 'newcustomer';
    } else if (title === '與前月相比成長率') {
      return 'growrate';
    } else if (title === '上月回收金額總計') {
      return 'recliam';
    }    
  }
  
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <CardMedia
          component="img"
          sx={{ 
            width: '80%', 
            height: 'auto', 
            objectFit: 'contain', 
            margin: '0 auto',  // 使圖片居中
            borderRadius: '50%', // 讓圖片顯示為圓形
            border: '2px solid #ccc' // 添加邊框，可自行修改邊框顏色和大小
          }} 
          image={`/assets/images/dashboard/${deterGif(title)}.gif`}
          alt={'瞇那賞'}
        />    

      <Typography sx={{ marginTop:2 }} variant="h3">{total !== 0 ? `${fShortenNumber(total)}${title.includes('率') ? '%' : ''}` : `0${title.includes('率') ? '%' : ''}`}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
