// FlexMessagePreview.js
import React from 'react';
import { Box, Typography, Button, Paper, Icon } from '@mui/material';

const FlexMessage1_1Preview = ({ flexMessage }) => {
  if (!flexMessage) {
    return <Typography>沒有 Flex Message 資料。</Typography>;
  }

  if (flexMessage.type === 'carousel') {
    return (
      <Box display="flex" overflow="auto">
        {flexMessage.contents.map((bubble, index) => (
          <Paper key={index} style={{ minWidth: '300px', marginRight: '16px', padding: '16px' }}>
            {/* 主圖像 */}
            {bubble.hero && (
              <img
                src={bubble.hero.url}
                alt="主圖像"
                style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
              />
            )}
            {/* 正文 */}
            {bubble.body && (
              <Box mt={2}>
                {bubble.body.contents.map((content, idx) => {
                  if (content.type === 'text') {
                    return (
                      <Typography
                        key={idx}
                        variant="h6"
                        style={{
                          fontWeight: content.weight === 'bold' ? 'bold' : 'normal',
                          color: content.color || '#000000',
                          fontSize: getFontSize(content.size),
                        }}
                      >
                        {content.text}
                      </Typography>
                    );
                  }
                  if (content.type === 'icon') {
                    return (
                      <Icon key={idx}>
                        <img src={content.url} alt="icon" style={{ width: '16px', height: '16px' }} />
                      </Icon>
                    );
                  }
                  if (content.type === 'filler') {
                    return (
                      <Typography key={idx} variant="body2" color="textSecondary">
                        {/* Filler 通常不顯示內容 */}
                      </Typography>
                    );
                  }
                  if (content.type === 'box' && content.layout === 'baseline') {
                    return (
                      <Box key={idx} display="flex" alignItems="baseline">
                        {content.contents.map((item, id) => {
                          if (item.type === 'text') {
                            return (
                              <Typography
                                key={id}
                                variant="body1"
                                style={{
                                  fontWeight: item.weight === 'bold' ? 'bold' : 'normal',
                                  color: item.color || '#000000',
                                  fontSize: getFontSize(item.size),
                                }}
                              >
                                {item.text}
                              </Typography>
                            );
                          }
                          if (item.type === 'icon' && item.url) {
                            return (
                              <Icon key={id}>
                                <img src={item.url} alt="icon" style={{ width: '16px', height: '16px' }} />
                              </Icon>
                            );
                          }
                          return null;
                        })}
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            )}
            {/* 頁尾按鈕 */}
            {bubble.footer && (
              <Box mt={2} display="flex" flexDirection="column" gap={1}>
                {bubble.footer.contents.map((button, btnIdx) => (
                  <Button
                    key={btnIdx}
                    variant={button.style === 'primary' ? 'contained' : 'outlined'}
                    color={button.style === 'primary' ? 'primary' : 'secondary'}
                    href={button.action.uri}
                    target="_blank"
                    fullWidth
                  >
                    {button.action.label}
                  </Button>
                ))}
              </Box>
            )}
          </Paper>
        ))}
      </Box>
    );
  }

  // 處理單一泡泡
  if (flexMessage.type === 'flex') {
    const bubble = flexMessage;
    return (
      <Paper style={{ padding: '16px', maxWidth: '600px' }}>
        {/* 主圖像 */}
        {bubble.hero && (
          <img
            src={bubble.hero.url}
            alt="主圖像"
            style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
          />
        )}
        {/* 正文 */}
        {bubble.body && (
          <Box mt={2}>
            {bubble.body.contents.map((content, idx) => {
              if (content.type === 'text') {
                return (
                  <Typography
                    key={idx}
                    variant="h6"
                    style={{
                      fontWeight: content.weight === 'bold' ? 'bold' : 'normal',
                      color: content.color || '#000000',
                      fontSize: getFontSize(content.size),
                    }}
                  >
                    {content.text}
                  </Typography>
                );
              }
              if (content.type === 'icon') {
                return (
                  <Icon key={idx}>
                    <img src={content.url} alt="icon" style={{ width: '16px', height: '16px' }} />
                  </Icon>
                );
              }
              if (content.type === 'filler') {
                return (
                  <Typography key={idx} variant="body2" color="textSecondary">
                    {/* Filler 通常不顯示內容 */}
                  </Typography>
                );
              }
              if (content.type === 'box' && content.layout === 'baseline') {
                return (
                  <Box key={idx} display="flex" alignItems="baseline">
                    {content.contents.map((item, id) => {
                      if (item.type === 'text') {
                        return (
                          <Typography
                            key={id}
                            variant="body1"
                            style={{
                              fontWeight: item.weight === 'bold' ? 'bold' : 'normal',
                              color: item.color || '#000000',
                              fontSize: getFontSize(item.size),
                            }}
                          >
                            {item.text}
                          </Typography>
                        );
                      }
                      if (item.type === 'icon' && item.url) {
                        return (
                          <Icon key={id}>
                            <img src={item.url} alt="icon" style={{ width: '16px', height: '16px' }} />
                          </Icon>
                        );
                      }
                      return null;
                    })}
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        )}
        {/* 頁尾按鈕 */}
        {bubble.footer && (
          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            {bubble.footer.contents.map((button, btnIdx) => (
              <Button
                key={btnIdx}
                variant={button.style === 'primary' ? 'contained' : 'outlined'}
                color={button.style === 'primary' ? 'primary' : 'secondary'}
                href={button.action.uri}
                target="_blank"
                fullWidth
              >
                {button.action.label}
              </Button>
            ))}
          </Box>
        )}
      </Paper>
    );
  }

  return <Typography>不支援的 Flex Message 類型。</Typography>;
};

// 根據文字大小返回對應的字體大小
const getFontSize = (size) => {
  switch (size) {
    case 'xxs':
      return '10px';
    case 'xs':
      return '12px';
    case 'sm':
      return '14px';
    case 'md':
      return '16px';
    case 'lg':
      return '18px';
    case 'xl':
      return '20px';
    default:
      return '14px';
  }
};

export default FlexMessage1_1Preview;
